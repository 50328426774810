import React from 'react'

import {
  Card,
  CardContent,
  Button,
  Stack,
  Box
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import './RoomCard.sass'
import { getLocalitation } from '../../utilities/utilities'
import { NOT_AVAILABLE } from '../../../variables'

const RoomCard = props => {
  const {
    room,
    status = {},
    selectedRoom = {},
    setSelectedRoom,
    handleOpenRoomDetails,
    handleSeeWhoBooked,
  } = props

  const { t } = useTranslation()


  return (
    <Card
      className={`RoomCard ${selectedRoom.id === room.id ? 'selected' : ''}`}
      onClick={() => setSelectedRoom({ label: status.label, ...room })}
    >
      <CardContent>
        <div className={`room-card-availability-label ${status.className}`}>
          {status.message}
        </div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="info">
            <h1>{room?.name}</h1>
            <ul>
              <li>{`${t('Location')}: ${getLocalitation(room)}`}</li>
              <li>{t('capacity')}: {room?.capacity} {t('seats')}</li>
            </ul>
          </div>
        </Stack>
        <Box className='buttons-container'>
          <Button
            className="see-details"
            onClick={() => handleOpenRoomDetails()}
          >
            {t('See details')}
          </Button>
          {
            status.label === NOT_AVAILABLE && (room?.usage && room?.usage[0]?.user?.first_name) !== '' &&
            <Button
              className="see-who-booked"
              variant='outlined'
              onClick={() => {
                setSelectedRoom(room)
                handleSeeWhoBooked()
              }}
            >
              {t('See who booked')}
            </Button>
          }
        </Box>
      </CardContent>
    </Card>
  )
}

export default RoomCard